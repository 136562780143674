<!-- @format -->

<template>
  <b-card title="会员信息编辑">
    <validation-observer ref="LevelEdit">
      <b-form>
        <b-row>
          <b-col md="4">
            <b-form-group label-cols="2" label-align="center" label="等级名称">
              <validation-provider #default="{ errors }" name="等级名称" rules="required">
                <b-form-input v-model="editInfo.name" :state="errors.length > 0 ? false : null" placeholder="输入等级名称" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group label-cols="2" label-align="center" label="佣金百分比">
              <validation-provider #default="{ errors }" name="佣金百分比" rules="required">
                <b-form-input v-model="editInfo.rate" :state="errors.length > 0 ? false : null" placeholder="输入佣金百分比" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group label-cols="2" label-align="center" label="续费百分比">
              <validation-provider #default="{ errors }" name="续费百分比" rules="required">
                <b-form-input v-model="editInfo.renew_rate" :state="errors.length > 0 ? false : null" placeholder="输入续费百分比" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group label-cols="2" label-align="center" label="等级TP值">
              <validation-provider #default="{ errors }" name="等级TP值">
                <b-form-input v-model="editInfo.level_tp" :state="errors.length > 0 ? false : null" placeholder="输入等级TP值" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button variant="primary" type="submit" @click.prevent="validationForm" :disabled="isRequest"> 提交 </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { computed, onUnmounted, reactive, ref, getCurrentInstance } from '@vue/composition-api'
import { required, url } from '@core/utils/validations/validations'

import store from '@/store'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import { BButton, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BRow, BCard } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { useRouter, throttle } from '@core/utils/utils'
import axios from '@/libs/axios'
import VipManagerModule from '@/views/GeneralManagement/VipManager/List/VipManagerModule'

export default {
  name: 'LevelEdit',
  components: {
    BFormRadioGroup,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    flatPickr,
    vSelect,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!vm.editInfo || !vm.editInfo.id) {
        vm.$router.back()
      }
    })
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'level-module'
    const { proxy } = getCurrentInstance()

    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, LevelManagerModule)
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const editInfo = computed(() => store.state[INVOICE_APP_STORE_MODULE_NAME].levelDetail)

    const { route, router } = useRouter()
    const isRequest = ref(false)

    const LevelEdit = ref(null)
    const validationForm = throttle(() => {
      LevelEdit.value.validate().then(success => {
        if (success) {
          isRequest.value = true

          delete editInfo.value.created_at
          delete editInfo.value.sort
          delete editInfo.value.com_code
          store
            .dispatch('level-module/editInvoices', { ...editInfo.value })
            .then(response => {
              if (response.code == 0) {
                proxy.$bvToast.toast(response.msg || '修改成功', {
                  title: '提示',
                  variant: 'success',
                  solid: true,
                  autoHideDelay: 2000,
                })
                const timer = setTimeout(() => {
                  isRequest.value = false
                  clearTimeout(timer)
                  router.back()
                }, 500)
              } else {
                proxy.$bvToast.toast(response.msg || '修改失败', {
                  title: '提示',
                  variant: 'danger',
                  solid: true,
                  autoHideDelay: 2000,
                })
              }
            })
            .catch(() => {
              isRequest.value = false
            })
        } else {
          isRequest.value = false
        }
      })
    }, 0)

    return {
      validationForm,

      isRequest,

      editInfo,
      required,
      url,

      LevelEdit,
    }
  },
}
</script>
