var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":"会员信息编辑"}},[_c('validation-observer',{ref:"LevelEdit"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-cols":"2","label-align":"center","label":"等级名称"}},[_c('validation-provider',{attrs:{"name":"等级名称","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"输入等级名称"},model:{value:(_vm.editInfo.name),callback:function ($$v) {_vm.$set(_vm.editInfo, "name", $$v)},expression:"editInfo.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-cols":"2","label-align":"center","label":"佣金百分比"}},[_c('validation-provider',{attrs:{"name":"佣金百分比","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"输入佣金百分比"},model:{value:(_vm.editInfo.rate),callback:function ($$v) {_vm.$set(_vm.editInfo, "rate", $$v)},expression:"editInfo.rate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-cols":"2","label-align":"center","label":"续费百分比"}},[_c('validation-provider',{attrs:{"name":"续费百分比","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"输入续费百分比"},model:{value:(_vm.editInfo.renew_rate),callback:function ($$v) {_vm.$set(_vm.editInfo, "renew_rate", $$v)},expression:"editInfo.renew_rate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-cols":"2","label-align":"center","label":"等级TP值"}},[_c('validation-provider',{attrs:{"name":"等级TP值"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"输入等级TP值"},model:{value:(_vm.editInfo.level_tp),callback:function ($$v) {_vm.$set(_vm.editInfo, "level_tp", $$v)},expression:"editInfo.level_tp"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit","disabled":_vm.isRequest},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" 提交 ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }